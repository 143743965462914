import { encodeQueryData, getImageFullPath } from '~/plugins/helpers'

const state = () => ({
  places: [],
  place: null,
  currentPage: 1,
  nextPage: 1,
  totalPages: 0,
  eventTypes: [],
})
const mutations = {
  SET_PLACES(state, places) {
    state.places = places
  },
  SET_NEXT_PAGE(state, page) {
    state.nextPage = page
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page
  },
  SET_TOTAL_PAGES(state, page) {
    state.totalPages = page
  },
  SET_PLACE(state, place) {
    state.place = place
  },
  SET_EVENT_TYPES(state, eventTypes) {
    console.log('🔴🔴 setting event type in home..')
    console.log(eventTypes)
    console.log('================')
    console.log(state.eventTypes)
    console.log('================')
    console.log('\n\n\n\n\n\n')
    state.eventTypes = eventTypes
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
}
const actions = {
  async getPlaces({ commit }, data = { page: 1 }) {
    try {
      // commit('TOGGLE_LOADER', null, { root:true });
      const querystring = encodeQueryData(data)
      const response = await this.$axios.$get('/venues?' + querystring)

      //TODO: to be removed, the function was causing a bug. it should be removed later.
      // if (
      //   typeof response === 'object' &&
      //   response !== null &&
      //   response.hasOwnProperty('data')

      // )
      // {

      let places = response.data
      //TODO: to be removed, the function was causing a bug. it should be removed later.
      // let places = response.data.map(e => {
      //   e.image = getImageFullPath(e.main_image);
      //   e.title = this.$i18n.locale == 'ar' ? e.arabic_name : e.name;
      //   //comment not by me till });
      //   // e.tags = e.event_types.filter(event=>{
      //   //             return event.tags && event.tags.length > 0;
      //   //           }).map(event=>{
      //   //             return event.tags.length > 0? event.tags[0]: [] ?? [];
      //   //           });
      //   return e;
      // });
      let nextPage = response.meta.last_page - response.meta.current_page
      commit('SET_PLACES', places)
      commit('SET_CURRENT_PAGE', response.meta.current_page)
      commit('SET_NEXT_PAGE', nextPage)
      commit('SET_TOTAL_PAGES', response.meta.last_page)
      commit('setCurrentPage', response.meta.current_page)
      return places
      // }

      throw response
    } finally {
      // commit('TOGGLE_LOADER', null, { root:true });
    }
  },

  async getPlaceDetails({ commit, dispatch, rootState }, data) {
    try {
      // commit('TOGGLE_LOADER', null, { root: true });
      const response = await this.$axios.$get('/venues/' + data.id)
      if (typeof response === 'object' && response !== null) {
        const place = response
        place.main_image = getImageFullPath(place.main_image)
        place.logo = getImageFullPath(place.logo)
        place.images = response.images
          .map((e) => {
            e.image = getImageFullPath(e.image)
            e.thumb = e.image
            e.src = e.image
            e.caption = e.tag_name
            return e
          })
          .reverse()
        place.imagesList = response.images.reverse()
        // place.booking_options = response.booking_options.map(e => {
        //   e.main_image = getImageFullPath('images/' + e.main_image);
        //   return e;
        // });
        place.tags = place.tags.map((tag) => {
          tag.color = '#' + tag.color
          return tag
        })
        place.booking_options = []
        commit('SET_PLACE', place)
        if (data?.isLoadOptions ?? true) {
          setTimeout(() => {
            dispatch(
              'searchBox/getBookingOptions',
              {
                withoutFilters: true,
                source: 'from home.js / place details / getPlaceDetails',
              },
              { root: true }
            )
          }, 400)
          // searchBox/getBookingOptions
          // this.getBookingOptions()
        }
        return place
      }

      throw response
    } finally {
      // commit('TOGGLE_LOADER', null, { root: true });
    }
  },

  async getEventTypes({ commit }, id) {
    try {
      // commit('TOGGLE_LOADER', null, { root:true });
      if (id) {
        const response = await this.$axios.$get(
          `venues/${id}/bookingOptionsEventTypes`
        )
        if (typeof response === 'object' && response !== null) {
          const eventTypes = response?.data?.map((e) => {
            e.text = e.display_name
            e.value = e.id
            return e
          })

          commit('SET_EVENT_TYPES', eventTypes)
          return response
        }
        throw response
      }
    } finally {
      // commit('TOGGLE_LOADER', null, { root:true });
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
