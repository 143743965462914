export const state = () => ({
  isOpen: false,
  isExpanded: true,
})

export const mutations = {
  toggleSidebar(state) {
    state.isOpen = !state.isOpen
    state.isExpanded = true
  },
  expandSidebar(state) {
    state.isExpanded = !state.isExpanded
    state.isOpen = false
  },
}