const state = () => ({
  hyperPayUrl: process.env.hyperPayUrl,
  checkoutId: null,
  checkoutMethod: null,
  type: 'deposit',
})
const mutations = {
  SET_CHECK_OUT_ID(state, id) {
    state.checkoutId = id
  },
  SET_CHECK_OUT_METHOD(state, method) {
    state.checkoutMethod = method
  },
  SET_PAYMENT_TYPE(state, type) {
    state.type = type
  },
}
const actions = {
  async deductAmount({ commit }, { id, payload, onFinish }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      let response = await this.$axios.$get(
        `myBookings/${id}/hyperpay/deductAmount`,
        {
          params: payload,
        }
      )
      onFinish(response)
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async getCheckOutId({ commit, state }, { id, payload }) {
    commit('SET_CHECK_OUT_ID', null)
    commit('SET_CHECK_OUT_METHOD', null)

    try {
      commit('TOGGLE_LOADER', null, { root: true })
      let response = await this.$axios.$get(
        `myBookings/${id}/hyperpay/getCheckoutId`,
        { params: payload }
      )
      commit('SET_CHECK_OUT_ID', response.checkout_id)
      commit('SET_CHECK_OUT_METHOD', payload.payment_method)
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async payUsingTabby({ commit }, id) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      let response = await this.$axios.$post(`myBookings/${id}/payUsingTabby`)
      response.tabbyUrl ? window.location.assign(response.tabbyUrl) : ''
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
}
export default {
  state,
  mutations,
  actions,
}
