import { eraseCookie } from '~/plugins/helpers'

export default function ({ $axios, store, redirect, app }) {
  $axios.onRequest((config) => {
    if (store.state.login.accessToken) {
      config.headers['Authorization'] = `Bearer ${store.state.login.accessToken}`
    }

    config.headers['Accept-Language'] = `${app.i18n.locale}`
    if (!config.url.includes('checkout')) {
      // dont add it to checkout
      config.headers['request-source'] = store.state.placeApp.isPlaceApp
        ? 'saas'
        : 'webapp'
    }
  })

  $axios.onResponse((response) => {
    return false
    console.log('[ RESPONSE ]' + 'the response is here')
  })

  $axios.defaults.withCredentials = true // to allow sending the cookie to the backend

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      doLogout()
    }

    throw error
  })

  const doLogout = () => {
    store.commit('TOGGLE_LOADER', null, { root: true })

    store.commit('login/SET_TOKEN', null)
    store.commit('login/SET_USER', null)
    store.commit('SET_LOGIN', false, { root: true })

    if (process.browser) {
      eraseCookie('token_' + process.env.CACHE_APP_VERSION ?? '-dev')
      eraseCookie('user_' + process.env.CACHE_APP_VERSION ?? '-dev')
      window.localStorage.removeItem(
        'token_' + process.env.CACHE_APP_VERSION ?? '-dev'
      )
      window.localStorage.removeItem(
        'user_' + process.env.CACHE_APP_VERSION ?? '-dev'
      )
    }

    const message = 'انتهت الجلسة، الرجاء اعادة تسجيل الدخول'
    store.commit(
      'SET_TOAST_ERROR',
      { showError: true, message },
      { root: true }
    )
    store.commit('TOGGLE_LOADER', null, { root: true })
    redirect('/#login')
  }
}
