const state = () => ({
  venueReviews: [],
  venueReviewsMeta: {},
})
const mutations = {
  CLEAR_VENUE_REVIEWS(state) {
    state.venueReviews = []
  },
  SET_VENUE_REVIEWS(state, venueReviews) {
    state.venueReviews = state.venueReviews.concat(venueReviews.data)
    state.venueReviewsMeta = venueReviews.meta
  },
}
const actions = {
  async getVenueReviews({ commit }, { id, params }) {
    const response = await this.$axios.$get(`/venues/${id}/reviews`, {
      params: params,
    })
    commit('SET_VENUE_REVIEWS', response)
  },
}

export default {
  state,
  mutations,
  actions,
}
