const baseUrl = process.env.baseAppUrl || 'http://qosoor.local'

export function encodeQueryData(data) {
  const ret = []
  for (const d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  return ret.join('&')
}

export function getImageFullPath(imagePath) {
  return imagePath ? `${baseUrl}/${imagePath}` : null
}

function hexToRgbConvertor(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function hexToRgb(hex) {
  const color = hexToRgbConvertor(hex)
  return `${color.r}, ${color.g}, ${color.b}` ?? null
}

export function getNearColor(rgb, degree) {
  const color = rgb.split(',')
  return (
    `${parseInt(color[0]) + degree}, ${parseInt(color[1]) + degree}, ${
      parseInt(color[2]) + degree
    }` ?? null
  )
}

export function displayError(e, self = null, message = null) {
  try {
    if (message) {
      return self && self.$swal(self.$t('error.title'), message, 'error')
    }
    const { response } = e
    const { request, ...errorObject } = response // take everything but 'request'
    if (response.status === 401) {
      return self && self.$swal(self.$t('error.title'), message, 'error')
    }
    let error = ''
    if (errorObject.data.errors) {
      for (const key in errorObject.data.errors) {
        error += `${errorObject.data.errors[key][0]}<br>`
      }
    }

    if (errorObject?.data?.error_message) {
      if (typeof errorObject.data.error_message === 'array') {
        for (const key in errorObject.data.error_message) {
          error += `${errorObject.data.error_message[key][0]}<br>`
        }
      } else if (typeof errorObject.data.error_message === 'object') {
        for (const key in errorObject.data.error_message) {
          error += `${errorObject.data.error_message[key][0]}<br>`
        }
      } else {
        error = errorObject.data.error_message
        if (error === 'booking.failed.expiredSession') {
          error = self.$t(error)
        }
      }
    }

    if (errorObject?.data?.error_codes) {
      for (const key in errorObject.data.error_codes) {
        if (errorObject.data.error_codes[key] === 'card_number_invalid') {
          error += `الرجاء التأكد من رقم البطاقة <br>`
        } else if (errorObject.data.error_codes[key] === 'cvv_invalid') {
          error += `الرجاء التأكد من كود التحقق الخاص بالبطاقة <br>`
        } else {
          error += `${errorObject.data.error_codes[key]}<br>`
        }
      }
    }
    return (
      self &&
      self.$swal({
        title: 'Error!',
        html: error.trim(),
        icon: 'error',
      })
    )
  } catch (error) {
    console.error(error, e, self)
    if (e.message) {
      return self && self.$swal(self.$t('error.title'), e.message, 'error')
    }
  }
}

export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + encodeURI(value || '') + expires + '; path=/'
}

export function getCookie(name, req = null, document = null) {
  const cookie = req ? req.headers.cookie : document?.cookie ?? null

  return decodeURI(
    cookie
      ?.split(';')
      ?.find((e) => e.includes(name))
      ?.split('=')[1] ?? null
  )
}

export function eraseCookie(name) {
  // const d = new Date();
  // d.setTime(d.getTime() - (24*60*60*1000));
  // let expires = "expires="+ d.toUTCString();
  // document.cookie = name+`=; ${expires};`;
  setCookie(name, null, -1)
}

/** @throw  */
export function validatePhoneNumber(number) {
  const phoneRegex = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
  if (!phoneRegex.test(number)) {
    throw new Error('Phone number is not valid. Must be start with number 5.')
  }
}

export function generateMetaData(place) {
  if (!place) return []

  return [
    { hid: 'title', property: 'title', content: place.name },
    {
      hid: 'description',
      property: 'description',
      content: place.about ?? place.name,
    },
    { hid: 'og:title', property: 'og:title', content: place.name },
    {
      hid: 'og-url',
      property: 'og:url',
      content: `https://${place.subdomain}.${process.env.rooturl}`,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: place.about ?? place.name,
    },
    {
      hid: 'og-image',
      property: 'og:image',
      content: getImageFullPath(place.main_image),
    },
    {
      hid: 'twitter-image',
      property: 'twitter:image',
      content: getImageFullPath(place.main_image),
    },
    {
      hid: 'twitter-title',
      property: 'twitter:title',
      content: place.name,
    },
    {
      hid: 'twitter-description',
      property: 'twitter:description',
      content: place.about ?? place.name,
    },
    {
      hid: 'twitter-url',
      property: 'twitter:url',
      content: `https://${place.subdomain}.${process.env.rooturl}`,
    },
  ]
}

// function for lock execute functions ( when need locked function we can use this function to make sure
// current function it's running only one instance at same time )
export const createLock = () => {
  let lockStatus = false

  const release = () => {
    lockStatus = false
  }

  const acquire = () => {
    if (lockStatus === true) {
      return false
    }
    lockStatus = true
    return true
  }

  return {
    lockStatus,
    acquire,
    release,
  }
}

export const changeTheme = (themeSettings) => {
  if (themeSettings) {
    const primaryRGB = hexToRgb(themeSettings.primary_color)
    const secondaryRGB = hexToRgb(themeSettings.secondary_color)
    const footerRGB = hexToRgb(themeSettings?.footer_color ?? '#390057')
    const callToActionColor = themeSettings?.call_to_action_color
      ? hexToRgb(themeSettings?.call_to_action_color)
      : '15,188,0'

    const textSecondaryRGB = hexToRgb(themeSettings.text_secondary_color)

    document.documentElement.style.setProperty('--color-primary', secondaryRGB)

    document.documentElement.style.setProperty(
      '--color-primary-dark',
      getNearColor(secondaryRGB, -70)
    )
    document.documentElement.style.setProperty(
      '--color-primary-light',
      getNearColor(secondaryRGB, 100)
    )
    const style = document.createElement('style')
    style.textContent = `
          .border-purple-500{
             --tw-text-opacity: 1;
             border-color: rgba(${primaryRGB}, var(--tw-bg-opacity));
          }
          .bg-purple-500{
            --tw-text-opacity: 1;
            background-color: rgba(${primaryRGB}, var(--tw-bg-opacity));
          }
          .hover\\:bg-purple-500:hover{
            --tw-text-opacity: 1;
            background-color: rgba(${primaryRGB}, var(--tw-bg-opacity));
          }
          .bg-purple-400 {
              --tw-bg-opacity: 0.8;
              background-color: rgba(${primaryRGB}, var(--tw-bg-opacity));
          }
          .text-purple-500 {
              --tw-text-opacity: 1;
              color: rgba(${textSecondaryRGB}, var(--tw-text-opacity));
          }
          .bg-violet {
              background-color: rgb(${footerRGB});
          }
          .bg-violet-light {
              background-color: rgb(${getNearColor(footerRGB, 35)});
          }
          .bg-green-light{
            background-color: rgb(${callToActionColor});
          }
          .border-green-light{
            border-color: rgb(${callToActionColor});
          }
          .bg-green-900{
            background-color: rgb(${getNearColor(callToActionColor, 35)});
          }
          .bg-green-light-800 {
            background-color: rgb(${getNearColor(callToActionColor, 30)});
          }
          .hover\\:bg-green-light-900:hover {
            background-color: rgb(${getNearColor(callToActionColor, 40)});
          }
          .hover\\:bg-green-light:hover{
            background-color: rgb(${callToActionColor});
          }
          .text-green-light{
            color: rgb(${callToActionColor});
          }
          .shadow-custom{
              box-shadow: -2px 10px 18px rgb(${getNearColor(
                secondaryRGB,
                -70
              )});
          }
        `
    if (themeSettings.button_text_color) {
      const buttonTextRGB = hexToRgb(themeSettings.button_text_color)
      style.textContent =
        style.textContent +
        `
             button.text-purple-500 {
              color: rgba(${buttonTextRGB}, var(--tw-text-opacity));
            }
          `
    }

    if (document.getElementById('Path_142')) {
      document.getElementById('Path_142').style.fill = getNearColor(
        secondaryRGB,
        140
      )
    }

    document.head.appendChild(style)
  }
}
