import moment from 'moment'
export default {
  install(Vue, options) {
    Vue.prototype.minDateInputMoment = moment().add(1,'days')
    Vue.prototype.minDateInput = moment().add(1,'days').locale('en').format('YYYY-MM-DD')
    Vue.prototype.maxDateInput = moment().add(1,'years').locale('en').format('YYYY-MM-DD')
    Vue.prototype.dateFormat = 'YYYY-MM-DD'
    Vue.prototype.dateInputFormat = {
      input: 'YYYY-MM-DD'
    }
  }
}
