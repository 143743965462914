import { eraseCookie, setCookie } from "~/plugins/helpers"

const state = () => ({
  isOpen: false,
  isOneStepModalOpen: false,
  accessToken: process.browser? window.localStorage.getItem('token_' + process.env.CACHE_APP_VERSION ?? '-dev'): null,
  user: process.browser? JSON.parse(window.localStorage.getItem('user_' + process.env.CACHE_APP_VERSION ?? '-dev')): null,
})

const mutations = {
  toggleModal(state) {
    state.isOpen = !state.isOpen
  },
  toggleOneStepLoginModal(state) {
    state.isOneStepModalOpen = !state.isOneStepModalOpen
  },
  SET_TOKEN(state, token){
    state.accessToken= token
  },
  SET_USER(state, user){
    state.user= user
  }
}
const  actions = {
  //"test": true
  async doLogin({ commit }, data) {
    commit('TOGGLE_LOADER', null, { root:true });

    try{
      const auth = await this.$axios.$post('/login', {
        ...data
      })

      if(typeof auth === 'object' &&
                auth !== null     &&
                auth.hasOwnProperty('success_message')){



        commit('SET_TOKEN', auth.token)
        commit('SET_USER', auth.user)
        commit('toggleLoggedIn', null, { root:true })
        commit('toggleModal')


        if (process.browser) {
          setCookie(
            'token_' + process.env.CACHE_APP_VERSION ?? '-dev',
            auth.token,
            7
          )
          setCookie(
            'user_' + process.env.CACHE_APP_VERSION ?? '-dev',
            JSON.stringify(auth.user),
            7
          )
          // document.cookie = `token=${auth.token};`;
          // document.cookie = `user=${JSON.stringify(auth.user)};`;
          window.localStorage.setItem(
            'token_' + process.env.CACHE_APP_VERSION ?? '-dev',
            auth.token
          )
          window.localStorage.setItem(
            'user_' + process.env.CACHE_APP_VERSION ?? '-dev',
            JSON.stringify(auth.user)
          )
        }

        const message = "تم تسجيل الدخول بنجاح" // todo: the message should be localized and maybe retrieved from the backend
        commit('SET_TOAST_SUCCESS', {showSuccess: true, message}, { root:true });
        return
      }

      throw auth
    }finally {
      commit('TOGGLE_LOADER', null, { root:true });
    }
  },

  async doRegister({ commit }, data) {
    commit('TOGGLE_LOADER', null, { root:true });

    try{
      const auth = await this.$axios.$post('/requestVerificationCode', {
        ...data,
        test: true,
        receives_info: true,
      })

      if(typeof auth === 'object' &&
                auth !== null     &&
                auth.hasOwnProperty('success_message')){
        commit('regModal/toggleModal', null, { root:true })
        commit('activateModal/toggleModal', null, { root:true })
        return
      }

      throw auth
    }finally {
      commit('TOGGLE_LOADER', null, { root:true });
    }
  },

  doLogout({ commit, state, rootState }) {
    commit('TOGGLE_LOADER', null, { root:true });

    commit('SET_TOKEN', null)
    commit('SET_USER', null)
    commit('SET_LOGIN', false, { root:true })

    if (process.browser) {
      eraseCookie('token_' + process.env.CACHE_APP_VERSION ?? '-dev')
      eraseCookie('user_' + process.env.CACHE_APP_VERSION ?? '-dev')
      window.localStorage.removeItem(
        'token_' + process.env.CACHE_APP_VERSION ?? '-dev'
      )
      window.localStorage.removeItem(
        'user_' + process.env.CACHE_APP_VERSION ?? '-dev'
      )
    }

    // if(rootState.placeApp.isPlaceApp){
      // const currentPlace = rootState.home.place
      // this.$router.push({ path: this.localePath(`/places/preview/${currentPlace.id}`) })
      // this.$router.push({ path: this.localePath(`/`) })
    // }else {
      this.$router.push({ path: this.localePath('/') })
    // }


    const message = "تم تسجيل الخروج بنجاح" // todo: the message should be localized and maybe retrieved from the backend
    commit('SET_TOAST_SUCCESS', {showSuccess: true, message}, { root:true });
    commit('TOGGLE_LOADER', null, { root:true });

  },

  async sendCode({commit, state}, data){
    try {

      commit('TOGGLE_LOADER', null, { root: true });

      const response = await this.$axios.$post(`/loginOrRegisterByPhone`, data)
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        return { message :response.success_message, code: response.vc }
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });

    }
  },
  async activateCode({commit, state}, data){
    try {

      commit('TOGGLE_LOADER', null, { root: true });

      const response = await this.$axios.$post(`/checkVerificationCode`, data)
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {

        commit('SET_TOKEN', response.token)
        commit('SET_USER', response.user)
        commit('toggleLoggedIn', null, { root:true })

        const message = "تم تسجيل الدخول بنجاح" // todo: the message should be localized and maybe retrieved from the backend
        commit('SET_TOAST_SUCCESS', {showSuccess: true, message}, { root:true });


        if (process.browser) {
          setCookie(
            'token_' + process.env.CACHE_APP_VERSION ?? '-dev',
            response.token,
            7
          )
          setCookie(
            'user_' + process.env.CACHE_APP_VERSION ?? '-dev',
            JSON.stringify(response.user),
            7
          )
          window.localStorage.setItem(
            'token_' + process.env.CACHE_APP_VERSION ?? '-dev',
            response.token
          )
          window.localStorage.setItem(
            'user_' + process.env.CACHE_APP_VERSION ?? '-dev',
            JSON.stringify(response.user)
          )
        }

        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });

    }
  }
}

export default {
  state,
  mutations,
  actions
}
