export const state = () => ({
  notProductionEnv: process.env.NODE_ENV !== 'production',
  isLoggedIn: false,
  isHiddenAppHint: false,
  isLoading: false,
  toastError: {
    showError: false,
    message: '',
  },
  toastSuccess: {
    showSuccess: false,
    message: '',
  },
  BreadCrumbsIdToName: [],
  showBreadCrumbs: false,
})

export const mutations = {
  toggleLoggedIn(state) {
    state.isLoggedIn = !state.isLoggedIn
  },
  SET_LOGIN(state, login) {
    state.isLoggedIn = login
  },
  SET_HIDDEN_APP_HINT(state, isHiddenAppHint) {
    state.isHiddenAppHint = isHiddenAppHint
  },
  TOGGLE_LOADER(state) {
    state.isLoading = !state.isLoading
  },
  SET_TOAST_ERROR(state, payload) {
    state.toastError = { ...state.toastError, ...payload }
  },
  SET_TOAST_SUCCESS(state, payload) {
    state.toastSuccess = { ...state.toastSuccess, ...payload }
  },
  SET_BREAD_CRUMBS_ID_TO_NAME(state, data) {
    state.BreadCrumbsIdToName = data
  },
  SET_SHOW_BREAD_CRUMBS(state, data) {
    state.showBreadCrumbs = data
  },
}

export const actions = {
  // nuxtServerInit({ dispatch, commit, app }, context) {
  //   const cookies = cparse.parse(context.req.headers.cookie || '')
  //   if (cookies.hasOwnProperty('x-access-token')) {
  //     app.$axios.setToken(cookies['x-access-token'], 'Bearer')
  //   }
  // },
  toggleLoader({ commit }) {
    commit('TOGGLE_LOADER', null)
  },
  setIsHiddenAppHint({ commit }, isHiddenAppHint) {
    commit('SET_HIDDEN_APP_HINT', isHiddenAppHint)
  },
}
