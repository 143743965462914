export default {
  install(Vue, options){
    Vue.prototype.showDangerToast = function(message)
    {
      this.$toasted.show(message, {position: 'bottom-right', duration: 2000, type: "error", icon : 'account-alert'})
    }

    Vue.prototype.showSuccessToast = function(message)
    {
      this.$toasted.show(message, {position: 'bottom-right', duration: 2000, type: "success", icon : 'account-alert'})
    }

  }
}
