import moment from 'moment'
import { page } from 'vue-analytics'
import {
  encodeQueryData,
  getImageFullPath,
  getCookie,
  setCookie,
  createLock,
} from '~/plugins/helpers'

// todo: yarob: ask baraa what he requested to do as init value for query
const initialQueryValues = {
  event_type: null,
  start_date: null,
  end_date: null,
  province: null,
  guests: {},
  event_type_object: null,
  is_separated: 1,
  branch: null,
  openSearchBoxModal: false,
} // todo: for now, is spearated will be always true, but we may change this if somethinkg is changed in the backend
const queryInTheStorage = process.browser
  ? window.localStorage.getItem(
      'query_' + process.env.CACHE_APP_VERSION ?? '-dev'
    )
  : null

const state = () => ({
  showTabbyModal: false,

  eventTypes: [],
  provinces: [],
  results: [],
  branches: [],
  query: queryInTheStorage
    ? { ...initialQueryValues, ...queryInTheStorage }
    : { ...initialQueryValues },
  nextPage: 1,
  currentPage: 1,
  totalPages: 1,
  totolaForReload: -1,
  showLoaderOptions: true,
  showEventModal: false,
  showBranchModal: false,
  showSearchBoxModal: false,
  selectedOptionForBooking: null,
  showDateModal: false,
  showGuestsModal: false,
  showProvinceModal: false,
  showServiceDetailsModal: false,
  selectedService: null,
  featuredVenues: [],
  discountedVenues: [],
  categories: [],
  optionTimeSlots: [],
  selected_start_time: null,
  selected_end_time: null,
})

const mutations = {
  SHOW_TABBY_MODAL(state, data) {
    state.showTabbyModal = data
  },
  SET_GUEST_INPUT_VALUE(state, data) {
    state.query.men_count = data.men_count
    state.query.women_count = data.women_count
  },
  SET_START_END_DATE(state, data) {
    state.query.start_date = data
    state.query.end_date = data
  },
  SET_EVENT_TYPE(state, data) {
    state.query.event_type = data
  },
  SET_LOGO_TRUE(state, index) {
    state.results[index].logo = true
  },
  SET_TOTAL_PAGES_FOR_RELOAD(state, totolaForReload) {
    state.totolaForReload = totolaForReload
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_FEATURED_VENUES(state, featuredVenues) {
    state.featuredVenues = featuredVenues
  },
  SET_DISCOUNTED_VENUES(state, discountedVenues) {
    state.discountedVenues = discountedVenues
  },
  SET_EVENT_TYPES(state, eventTypes) {
    state.eventTypes = eventTypes
  },
  SET_PROVINCES(state, provinces) {
    state.provinces = provinces
  },
  SET_BRANCHES(state, branches) {
    state.branches = branches
  },

  SET_PLACES(state, results) {
    state.results = results
  },
  SET_NEXT_PAGE(state, page) {
    state.nextPage = page
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page
  },
  SET_TOTAL_PAGES(state, page) {
    state.totalPages = page
  },
  SET_SEARCH_QUERY(state, query) {
    console.log('SET_SEARCH_QUERY', query)
    console.log(
      'SET_SEARCH_QUERY >> current state before change: ',
      state.query
    )

    const allQuery = { ...state.query, ...query } // instead of replacing the query object by a new one, we should keep the old data and append the new data to them
    console.log('now all query issssss ', allQuery)
    state.query = allQuery
  },
  STORE_SEARCH_QUERY(state, query) {
    const allQuery = { ...state.query, ...query } // instead of replacing the query object by a new one, we should keep the old data and append the new data to them
    state.query = allQuery

    if (process.browser) {
      // todo: yarob: ask baraa why are we using both cookie & local storage
      setCookie(
        'query_' + process.env.CACHE_APP_VERSION ?? '-dev',
        JSON.stringify(allQuery),
        7
      )
      window.localStorage.setItem(
        'query_' + process.env.CACHE_APP_VERSION ?? '-dev',
        JSON.stringify(allQuery)
      )
    }
  },
  LOADER_OPTIONS_TOGGLE(state, value = null) {
    state.showLoaderOptions = value ?? !state.showLoaderOptions
  },
  TOGGLE_SHOW_EVENT_MODAL(state) {
    state.showEventModal = !state.showEventModal
  },
  TOGGLE_SHOW_BRANCH_MODAL(state) {
    state.showBranchModal = !state.showBranchModal
  },
  TOGGLE_SEARCH_BOX_MODAL(state, value = null) {
    state.showSearchBoxModal = value ?? !state.showSearchBoxModal
  },
  SET_SELECTED_OPTION_FOR_BOOKING(state, value = null) {
    state.selectedOptionForBooking = value
  },
  TOGGLE_SHOW_DATE_MODAL(state) {
    state.showDateModal = !state.showDateModal
  },
  TOGGLE_SHOW_GUESTS_INPUT_MODAL(state) {
    state.showGuestsModal = !state.showGuestsModal
  },
  TOGGLE_SHOW_PROVINCE_MODAL(state) {
    state.showProvinceModal = !state.showProvinceModal
  },
  TOGGLE_SHOW_SERVICE_DETAILS_MODAL(state, isOpen = null) {
    if (isOpen !== null) {
      state.showServiceDetailsModal = isOpen
      return null
    }
    state.showServiceDetailsModal = !state.showServiceDetailsModal
    state.selectedService = null
  },
  TOGGLE_SHOW_SERVICE_DETAILS_MODAL_WITH_DATA(state, data) {
    state.selectedService = data
    state.showServiceDetailsModal = true
  },
  SET_OPTION_TIME_SLOTS(state, slots) {
    state.optionTimeSlots = slots
  },
  SET_START_AND_END_TIME(state, data) {
    state.selected_start_time = data.start_time
    state.selected_end_time = data.end_time
  },
}
const actions = {
  setLogoTrue({ commit }, index) {
    commit('SET_LOGO_TRUE', index)
  },

  async getCategoriesByEventType({ commit, state }) {
    const response = await this.$axios.$get('/categories', {
      params: {
        event_type: state.query.event_type,
      },
    })
    commit('SET_CATEGORIES', response.categories)
  },
  async getFeaturedVenus({ commit, state }) {
    const response = await this.$axios.$get('/featuredVenues', {
      params: {
        event_type: state.query.event_type_object
          ? state.query.event_type_object.id
          : null,
        men_count: state.query.men_count,
        women_count: state.query.women_count,
        province: state.query.province,
      },
    })
    commit('SET_FEATURED_VENUES', response.data)
  },
  async getDiscountedVenus({ commit, state }) {
    const response = await this.$axios.$get('/discountedVenues', {
      params: {
        event_type: state.query.event_type_object
          ? state.query.event_type_object.id
          : null,
        men_count: state.query.men_count,
        women_count: state.query.women_count,
        province: state.query.province,
      },
    })
    commit('SET_DISCOUNTED_VENUES', response.data)
  },
  async getEventTypes({ commit }, id = null) {
    try {
      const response = await this.$axios.$get(
        id ? `venues/${id}/bookingOptionsEventTypes` : '/allEventTypes'
      )
      if (typeof response === 'object' && response !== null) {
        const eventTypes = (id ? response?.data : response)?.map((e) => {
          e.text = e.display_name
          e.value = e.id
          return e
        })

        commit('SET_EVENT_TYPES', eventTypes)
        return response
      }

      throw response
    } finally {
      // commit('TOGGLE_LOADER', null, { root:true });
    }
  },

  async getProvinces({ commit }) {
    try {
      const response = await this.$axios.$get('/availableProvinces')
      if (typeof response === 'object' && response !== null) {
        const provinces = response.map((e) => {
          e.text = this.$i18n.locale == 'ar' ? e.arabic_name : e.name
          e.value = e.id
          return e
        })

        commit('SET_PROVINCES', provinces)
        return response
      }

      throw response
    } finally {
      // commit('TOGGLE_LOADER', null, { root:true });
    }
  },

  async getBranches({ commit, rootState }, id) {
    try {
      const isPlaceApp = rootState.placeApp.isPlaceApp

      const response = await this.$axios.$get(`/venues/${id}/places`)
      if (typeof response === 'object' && response !== null) {
        let branches = response?.places?.map((e) => {
          e.text = this.$i18n.locale === 'ar' ? e.arabic_name : e.name
          e.value = e.id
          return e
        })
        if (isPlaceApp) {
          branches = branches.filter((place) => place.subdomain !== null)
        }

        commit('SET_BRANCHES', branches)
        return response
      }

      throw response
    } finally {
      // commit('TOGGLE_LOADER', null, { root:true });
    }
  },

  async getPlaces({ commit, state }, data = { page: 1 }) {
    const query = state.query
    // first make srue all the query params are there..
    const { isValid, message } = validateFilterInputs(query, commit)
    if (!isValid) {
      commit('SET_TOAST_ERROR', { showError: true, message }, { root: true })
      return
    }
    // make sure to add the filteration query params to the request
    let category = data.category_id
    let type = data.type_id
    let page = data.page

    data = { ...data, ...state.query }
    if (page) data.page = page

    if (category) data.category_id = category
    else delete data.category_id
    if (type) data.type_id = type
    else delete data.type_id
    delete data.types
    delete data.category
    delete data.type
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const querystring = encodeQueryData(data)

      const response = await this.$axios.$get('/venues?' + querystring)

      if (
        typeof response === 'object' &&
        response !== null &&
        response.hasOwnProperty('data')
      ) {
        const places = response.data.map((e) => {
          e.image = getImageFullPath(e.main_image)
          e.title = this.$i18n.locale == 'ar' ? e.arabic_name : e.name
          // e.tags = e.event_types.filter(event=>{
          //             return event.tags && event.tags.length > 0;
          //           }).map(event=>{
          //             return event.tags.length > 0? event.tags[0]: [] ?? [];
          //           });
          return e
        })
        const nextPage = response.meta.last_page - response.meta.current_page
        commit('SET_PLACES', places)
        commit('STORE_SEARCH_QUERY', data)
        commit('SET_CURRENT_PAGE', response.meta.current_page)
        commit('SET_NEXT_PAGE', nextPage)
        commit('SET_TOTAL_PAGES', response.meta.last_page)
        commit('SET_TOTAL_PAGES_FOR_RELOAD', response.meta.total)

        // redirect to the search page..
        this.$router.push({
          path: this.localePath('/search'),
          query: data,
        })
        return places
      }

      // todo: yarob: throw response ?!
      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  // todo: yarob: why there is a method named set search query while there is already a commit with the same name?
  async setSearchQuery({ commit, state, rootState }, data) {
    // if(!data.start_date) throw new Error('date is required');

    const tomorrow = moment().add(1, 'days').locale('en').format('YYYY-M-D')
    if (!data.start_date && !state.query.start_date) {
      data.start_date = tomorrow
      data.end_date = tomorrow
    }
    commit('SET_SEARCH_QUERY', data)
    if (data.hasOwnProperty('id') && data.id != null) {
      // && rootState.isLoggedIn
      try {
        commit('TOGGLE_LOADER', null, { root: true })
        const id = data.id
        delete data.id
        const querystring = encodeQueryData(data)

        // todo: yarob: why are we getting booking options in a method named set search query ??
        const response = await this.$axios.$get(
          `/venues/${id}/bookingOptions?${querystring}`
        )
        if (typeof response === 'object' && response !== null) {
          const options = response.map((e) => {
            e.main_image = getImageFullPath(e.main_image)
            e.title = this.$i18n.locale == 'ar' ? e.arabic_name : e.name
            return e
          })
          commit(
            'home/SET_PLACE',
            { ...rootState.home.place, booking_options: options },
            { root: true }
          )
          return options
        }
        throw response
      } finally {
        commit('TOGGLE_LOADER', null, { root: true })
        commit('LOADER_OPTIONS_TOGGLE', false)
      }
    }
  },

  async getBookingOptions(
    { commit, state, rootState },
    filters = { withoutFilters: false }
  ) {
    const place = rootState.home.place

    // make sure to add the filteration query params to the request's body
    const data = filters?.withoutFilters === true ? {} : { ...state.query }

    // setup the province to be the one of the current place
    data.province = filters?.province ? filters?.province : place.city_id

    // setup the id to be the current place id
    // Yarob: TODO: @Baraa I think you reversed the condition am I right?
    data.id = filters?.id ? filters.id : place.id

    data.source = filters?.source

    // set the default value of the guests inputs if they are not set yet
    data.event_type_object?.guests_inputs?.forEach((input) => {
      if (isEmpty(data[input.key])) {
        // make sure the input of the selected event type is not empty in the inputs (query params)
        data[input.key] = '20' // todo: for now, 20 will be the defualt value, however, it should be derived from the backend
      }
    })

    // setup the default start date/ end date if it's not specified
    if (!data.start_date) {
      const date = moment().add(60, 'day').locale('en').format('Y-MM-DD')
      data.start_date = date
      commit('STORE_SEARCH_QUERY', {
        start_date: data.start_date,
      })
    }

    if (!data.end_date) {
      data.end_date = moment().add(60, 'day').locale('en').format('Y-MM-DD')
      commit('STORE_SEARCH_QUERY', {
        end_date: data.end_date,
      })
    }

    // first make srue all the query params are there..
    const { isValid, message } = validateFilterInputs(
      data,
      commit,
      filters?.withoutFilters === true,
      null,
      filters?.openSearchBoxModal === true
    )
    if (!isValid) {
      commit('LOADER_OPTIONS_TOGGLE', false)
      return
    }

    // first make srue all the query params are there..
    // const {isValid, message} = validateFilterInputs(query)
    // if(!isValid){
    //   console.log("showing toaaast ")
    //   commit('SET_TOAST_ERROR', {showError: true, message}, { root:true });
    //   return
    // }

    if (data.hasOwnProperty('id') && data.id != null) {
      // && rootState.isLoggedIn

      try {
        // commit('TOGGLE_LOADER', null, { root: true })

        // commit('TOGGLE_LOADER', null, { root:true });
        const id = data.id
        delete data.id
        console.log(data)
        if (data.event_type == 1 || data.event_type == 3) {
          ;('eee')
        } else {
          delete data.people_count
          delete data.women_count
        }
        const querystring = encodeQueryData(data)
        const response = await this.$axios.$get(
          `/venues/${id}/bookingOptions?${querystring}`
        )

        if (typeof response === 'object' && response !== null) {
          const options = response.map((e) => {
            e.main_image = getImageFullPath(e.main_image)
            e.title = this.$i18n.locale === 'ar' ? e.arabic_name : e.name
            e.images = [...(e.images || [])]
            return e
          })
          commit(
            'home/SET_PLACE',
            { ...rootState.home.place, booking_options: options },
            { root: true }
          )
          return options
        }
        throw response
      } catch (error) {
        console.log(error)
      } finally {
        commit('LOADER_OPTIONS_TOGGLE', false)
      }
    }
  },
  async getBookingOptionTimeSlots({ commit, state }, data) {
    try {
      const optionId = data.optionId

      let q = {
        start_date: state.query.start_date,
      }
      const querystring = encodeQueryData(q)

      const response = await this.$axios.$get(
        `bookingOptions/${optionId}/getOptionTimeSlots?${querystring}`
      )

      commit('SET_OPTION_TIME_SLOTS', response.time_slots)
      commit('SET_START_AND_END_TIME', { start_time: null, end_time: null })

      return response
    } catch (error) {
      console.log(error)
    } finally {
      commit('LOADER_OPTIONS_TOGGLE', false)
    }
  },

  routeToBookingPage({ commit, state, rootState }, bookingOption) {
    const place = rootState.home.place
    const isPlaceApp = rootState.placeApp.isPlaceApp
    const selectedService = rootState.booking.selectedService
    const bookingNote = rootState.booking.bookingNote
    const query = state.query
    const optionObj =
      rootState.home.place?.booking_options?.find(
        ({ id }) => id === parseInt(bookingOption)
      ) ?? null
    // first make srue all the query params are there..
    const { isValid, message } = validateFilterInputs(
      query,
      commit,
      false,
      bookingOption,
      true
    )
    if (!isValid) {
      commit('SET_TOAST_ERROR', { showError: true, message }, { root: true })
      return
    }

    if (optionObj.is_time_slotted) {
      if (!state.selected_start_time || !state.selected_end_time) {
        commit(
          'SET_TOAST_ERROR',
          { showError: true, message: 'الرجاء اختيار أحد الأوقات المتاحة' },
          { root: true }
        )
        return
      }
    }

    const absolutePath = !isPlaceApp
      ? `/places/${place.id}`
      : `/${bookingOption}`
    let fullPath = !isPlaceApp
      ? this.localePath(`${absolutePath}/booking?option=${bookingOption}`)
      : `${absolutePath}/booking?option=${bookingOption}`

    if (selectedService) {
      fullPath += `&service=${selectedService.id}`
    }

    if (bookingNote) {
      fullPath += `&bookingNotes=${encodeURIComponent(bookingNote)}`
    }
    commit('STORE_SEARCH_QUERY', query)
    this.$router.push(fullPath)
  },
  routeToBookingOptionPage({ commit, state, rootState }, bookingOption) {
    // console.warn('searchBox >> routeToBookingOptionPage')
    // console.warn('current query is: ', state.query)

    const place = rootState.home.place
    const isPlaceApp = rootState.placeApp.isPlaceApp
    const query = state.query

    const absolutePath = !isPlaceApp ? `/places/details/${place.id}` : `/`
    const fullPath = !isPlaceApp
      ? `${absolutePath}/options/${bookingOption}`
      : `${absolutePath}options/${bookingOption}`

    this.$router.push({
      path: fullPath,
      query: {
        event_type: query.event_type,
      },
    })
  },
}

const getters = {
  // startDate(state) {
  //   const currentDate = state.query.start_date
  // }
}

/// //// HELPER METHODS ///////
function validateFilterInputs(
  inputs,
  commit,
  withoutFilters = false,
  bookingOption = null,
  openSearchBoxModal = false
) {
  const lock = createLock() // create a lock
  let message = ''
  let isValid = true

  if (!lock.acquire()) {
    return { isValid: false, message: 'جاري التحقق من المعلومات ...' }
  }
  // alert('get options 1:2: ' + (!withoutFilters ) + ', ' +
  //   (isEmpty(inputs.event_type) || isEmpty(inputs.start_date)))

  if (
    !withoutFilters &&
    (isEmpty(inputs.event_type) || isEmpty(inputs.start_date))
  ) {
    // alert('get options 2: ' + openSearchBoxModal + ", source: " + inputs.source)

    commit('SET_SELECTED_OPTION_FOR_BOOKING', bookingOption)

    // if (openSearchBoxModal) {

    commit('TOGGLE_SEARCH_BOX_MODAL', true)
    // message = 'الرجاء تحديد بيانات المناسبة'
    isValid = false
    lock.release()
    return { isValid, message }
    // }
  }
  if (!withoutFilters && isEmpty(inputs.event_type)) {
    // commit('TOGGLE_SHOW_EVENT_MODAL', true)
    message = null
    // message = 'الرجاء تحديد نوع المناسبة'
    isValid = false
    lock.release()
    return { isValid, message }
  }

  // if (isEmpty(inputs.province)) {
  //   message = 'الرجاء تحديد المنطقة'
  //   isValid = false
  //   lock.release()
  //   return { isValid, message }
  // }

  if (isEmpty(inputs.start_date)) {
    commit('TOGGLE_SHOW_DATE_MODAL', true)
    message = 'الرجاء تحديد تاريخ المناسبة'
    isValid = false
    lock.release()
    // return { isValid, message }
  }

  // if the date is not empty, then make sure it's > min allowed date
  const minDate = moment().add(2, 'days').locale('en') // the minimum date to make a booking is tomorrow
  const currentDate = moment(inputs.start_date)
  if (currentDate.isBefore(minDate, 'day')) {
    // console.warn('🔴🔴🔴 current date is: ', currentDate.format('Y-MM-DD'))
    // message = 'التاريخ المحدد غير صالح، الرجاء اختيار تاريخ آخر'
    message = 'تم تحديث تاريخ الحجز'
    // isValid = false
    commit('SET_SEARCH_QUERY', {
      start_date: minDate.format('Y-MM-DD'),
      end_date: minDate.format('Y-MM-DD'),
    })
    commit(
      'booking/SET_SEARCH_QUERY',
      {
        start_date: minDate.format('Y-MM-DD'),
        end_date: minDate.format('Y-MM-DD'),
      },
      { root: true }
    )
    commit('STORE_SEARCH_QUERY', {
      start_date: minDate.format('Y-MM-DD'),
      end_date: minDate.format('Y-MM-DD'),
    })
    inputs.start_date = minDate.format('Y-MM-DD')
    inputs.end_date = minDate.format('Y-MM-DD')
    // commit('SET_TOAST_SUCCESS', { showSuccess: true, message }, { root: true })
    isValid = true
    lock.release()
    return { isValid, message }
  }

  // validating the guests inputs based on the selected event type
  if (inputs.event_type_object) {
    // if it's not null
    inputs.event_type_object?.guests_inputs?.forEach((input) => {
      if (isEmpty(inputs[input.key])) {
        // make sure the input of the selected event type is not empty in the inputs (query params)
        message = 'الرجاء تحديد'
        message += ` ${input.name}`
        isValid = false
      }
    })

    if (!withoutFilters && !isValid) {
      if (openSearchBoxModal) {
        commit('TOGGLE_SEARCH_BOX_MODAL', true)
      }
      commit('SET_SELECTED_OPTION_FOR_BOOKING', bookingOption)

      // commit('TOGGLE_SHOW_GUESTS_INPUT_MODAL', true)
    }
    lock.release()
    return { isValid, message }
  }

  // if (this.isEmpty(this.filterEventInput))
  // {
  //   message = "الرجاء تحديد نوع المناسبة"
  //   isValid = false
  // }

  return { isValid, message }
}

function isEmpty(value) {
  return (
    value == '' ||
    value == null ||
    value == undefined ||
    value == NaN ||
    value == 'NaN'
  )
}

/// ////===  HELPER METHODS ===///////

export default {
  state,
  mutations,
  actions,
  getters,
}
