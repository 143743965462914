
import Vue from 'vue'

import AuthManager from '../AuthManager'
import ToastManager from './ToastManager'

// DATE MANAGER
import DateManager from './DateInputManager'

// Filter Manager
import FilterManager from './FilterManager'

// ALERT Manager
import AlertManager from './AlertManager'

// GENERAL Manager
import GeneralManager from './GeneralManager'

// Search query url Manager
import SearchURLQueryManager from './SearchURLQueryManager'

// you can also pass options, check options reference below
Vue.use(ToastManager)
Vue.use(DateManager)
Vue.use(FilterManager)
Vue.use(AuthManager)
Vue.use(AlertManager)
Vue.use(GeneralManager)
Vue.use(SearchURLQueryManager)
