import _ from 'lodash'
import { encodeQueryData } from '~/plugins/helpers'

export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        // EVENT TYPE INPUT
        filterEventInput: {
          get() {
            return this.$store?.state.searchBox.query?.event_type ?? null
          },
          set(value) {
            console.log("let'ssss filterrrrrrr")

            // make sure filterEventTypes is not null + it's an array
            if (!this.filterEventTypes || !_.isArray(this.filterEventTypes)) {
              return
            }

            const eventTypeObject =
              this.filterEventTypes?.find((type) => {
                // Yarob: I had to use this code here instead of accessing the computed property cuz it won't be computed to the new value at this stage 🤷‍♂️
                return type.id === parseInt(value)
              }) ?? null
            console.log('doneeeeee')

            this.$store.commit('searchBox/SET_SEARCH_QUERY', {
              event_type: parseInt(value),
              event_type_object: eventTypeObject,
            })

            // $nuxt.$emit('event-type-changed', eventTypeObject)
          },
        },

        // START DATE INPUT
        filterStartDateInput: {
          get() {
            // the value in the store is string, so make sure to convert it to date before getting it
            const date = this.$store?.state.searchBox.query?.start_date ?? null

            return date
              ? this.$moment(this.filterQuery.start_date).toDate()
              : null
          },
          set(value) {
            console.log('🟠🟠 setting start date from filter manager', value)
            // the value will be a date object, so make sure to convert it to string before storing it
            const stringDate = this.$moment(value)
              .locale('en')
              .format('Y-MM-DD')
            console.log(
              '🟠🟠 After conversion: setting start date from filter manager',
              stringDate
            )
            this.$store.commit('searchBox/SET_SEARCH_QUERY', {
              start_date: stringDate,
              end_date: stringDate,
            })
          },
        },

        // REGION INPUT
        filterRegionInput: {
          get() {
            return this.$store?.state.searchBox.query?.province ?? null
          },
          set(value) {
            this.$store.commit('searchBox/SET_SEARCH_QUERY', {
              province: value,
            })
          },
        },

        // Branch INPUT
        filterBranchInput: {
          get() {
            let branch = this.$store?.state.searchBox.query?.branch ?? null
            const branches = this.$store?.state.searchBox?.branches ?? []
            if (!branch && branches.length > 0) {
              // if the branch is not set, then set current place to the default branch
              branch = this.$store.state.home.place?.id ?? null
              this.$store.commit('searchBox/SET_SEARCH_QUERY', { branch })
            }
            return branch
          },
          set(value) {
            const branch =
              this.$store?.state.searchBox?.branches?.find((branch) => {
                return branch.id === parseInt(value)
              }) ?? null
            // change the branch in the store
            this.$store.commit('searchBox/SET_SEARCH_QUERY', {
              branch: branch.id,
              id: branch.id,
            })
            branch.booking_options = []
            this.$store.commit('home/SET_PLACE', {
              place: branch,
            })
            // goto the branch page
            this.goToBranchPage(branch)
          },
        },

        filterSelectedEventType() {
          if (this.filterEventTypes && _.isArray(this.filterEventTypes)) {
            return this.filterEventTypes?.find(
              (type) => type.id == this.filterEventInput
            )
          }
        },

        filterEventTypes() {
          return this.$store?.state.searchBox.eventTypes ?? []
        },
        filterBranches() {
          return this.$store?.state.searchBox.branches ?? []
        },

        // GUESTS INPUTS
        filterGuestsInputs: {
          get() {
            let inputs = []
            if (this.filterSelectedEventType?.guests_inputs) {
              inputs = this.filterSelectedEventType?.guests_inputs.map(
                (input) => {
                  // if (input.key === 'people_count') {
                  //   this.filterSelectedEventType.guests_inputs.men_count = {
                  //     key: 'men_count',
                  //     ...input,
                  //   }
                  //   input.key = 'men_count'
                  // }

                  // console.log('filterGuestsInputs: ', input)

                  const value = this.filterQuery[input.key] ?? null // get the stored value if there is any
                  return { ...input, value } // todo: set the initial values for different inputs
                }
              )
            }
            return inputs
          },

          set(value) {
            // this.guestsInputForSelectedEventType.forEach((input) => {
            //   guestsInputs[input.key] = input.value
            // })
          },
        },

        filterQuery() {
          return this.$store.state.searchBox.query ?? []
        },

        hasSelectedFilters(excluded = []) {
          if (!excluded || _.isArray(excluded) === false) {
            return false
          }
          // excluded is used for any filter that could be excluded from validation
          if (
            this.isEmptyFilter(this.filterQuery.event_type) &&
            !excluded.includes('event_type')
          ) {
            return false
          }

          if (
            this.isEmptyFilter(this.filterQuery.province) &&
            !excluded.includes('province')
          ) {
            return false
          }

          if (
            this.isEmptyFilter(this.filterQuery.start_date) &&
            !excluded.includes('start_date')
          ) {
            return false
          }

          // validating the guests inputs based on the selected event type
          if (this.filterSelectedEventType) {
            // if it's not null
            let guestsAreValid = true // Yarob: because js is an amazing programming language that we can't use (return false) inside (forEach) cuz it means something else 🤷‍♂️
            this.filterSelectedEventType.guests_inputs.forEach((input) => {
              if (this.isEmptyFilter(this.filterQuery[input.key])) {
                // make sure the input of the selected event type is not empty in the inputs (query params)
                guestsAreValid = false
              }
            })

            if (!guestsAreValid) return false
          }

          return true
        },
      },

      methods: {
        // this methods should be used with @change inside all the guests inputs
        guestsInputValueChanged(event, key) {
          const payload = {}
          payload[key] = event.target.value

          this.$store.commit('searchBox/SET_SEARCH_QUERY', payload)
        },

        // this methods is used to ensure that all the current filteration inputs are filled and nothing is left empty
        allFiltersAreFilled(excluded = []) {
          // excluded is used for any filter that could be excluded from validation
          if (
            this.isEmptyFilter(this.filterQuery.event_type) &&
            !excluded.includes('event_type')
          ) {
            return false
          }

          if (
            this.isEmptyFilter(this.filterQuery.province) &&
            !excluded.includes('province')
          ) {
            return false
          }

          if (
            this.isEmptyFilter(this.filterQuery.start_date) &&
            !excluded.includes('start_date')
          ) {
            return false
          }

          // validating the guests inputs based on the selected event type
          if (this.filterSelectedEventType) {
            // if it's not null
            let guestsAreValid = true // Yarob: because js is an amazing programming language that we can't use (return false) inside (forEach) cuz it means something else 🤷‍♂️
            this.filterSelectedEventType.guests_inputs.forEach((input) => {
              if (this.isEmptyFilter(this.filterQuery[input.key])) {
                // make sure the input of the selected event type is not empty in the inputs (query params)
                guestsAreValid = false
              }
            })

            if (!guestsAreValid) return false
          }

          return true
        },

        isEmptyFilter(value) {
          return value == '' || value == null
        },

        // go to branch page with the current filteration
        goToBranchPage(place) {
          if (this.isPlaceApp) {
            const domain = process.env.SAAS_APP_DOMAIN_SCHEMA.replace(
              '{subdomain}',
              place.subdomain
            )
            // goto new sub domain with current filteration
            window.location.href = `${domain}/?${encodeQueryData(
              this.getCurrentQuery()
            )}`
          } else {
            // goto place page with current filteration
            window.location.href = `/places/details/${
              place.id
            }?${encodeQueryData(this.getCurrentQuery())}`
            // const newURL = `/places/details/${place.id}`
            // this.$router.push({
            //   path: newURL,
            //   query: this.getCurrentQuery(),
            // })
          }
        },
        getCurrentQuery() {
          const filters = {
            date: this.$store.state.searchBox?.query?.start_date,
            men_count: this.$store.state.searchBox?.query?.men_count,
            women_count: this.$store.state.searchBox?.query?.women_count,
            event_type: this.$store.state.searchBox?.query?.event_type,
          }

          return Object.keys(filters)
            .filter((key) => filters[key] != null)
            .reduce((obj, key) => {
              return Object.assign(obj, {
                [key]: filters[key],
              })
            }, {})
        },
      },
    })
  },
}
