import Vue from 'vue'
import Router from 'vue-router'
import checkSubdomain from '../plugins/subdomain'

Vue.use(Router)

export const createRouter = async function createRouter(
  ssrContext,
  createDefaultRouter,
  routerOptions
) {
  const options = routerOptions || createDefaultRouter(ssrContext).options // get the original defaul routes options defined by nuxt

  // get the host name regardless of its source (server > host || clien > location)
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host // in the server side, we get the host from the request headers and in the client side, we get it from the location object >> both of them represent the hostname
  const path = ssrContext ? ssrContext.req.url : location.pathname // in the server side, we get the path from the request url and in the client side, we get it from the location object >> both of them represent the path of the URL
  console.log('the path issss ', path)
  const routes = await fixRoutes(options.routes, hostname, path) // fix the routes based on the received hostname
  return new Router({
    ...options,
    routes,
  })
}
async function fixRoutes(defaultRoutes, hostname, path = null) {
  const appType = process.env.appType
  console.log('\n\n\n====================')
  console.log(
    'app type: ',
    process.env.APP_TYPE,
    process.env.dotenv,
    process.env.appType
  )
  const parts = hostname.split('.')
  const domainLength = 3 // route1.example.com => domain length = 3

  // to make the DX better, we want to show the sub domain routes even if the vistited url is localhost (not something like: subDomain.eventful.sa), therefore we need to check if the hostname is localhost or not and the first path is /saas
  if (hostname.includes('localhost')) {
    if (appType === 'saas') {
      const saasName = process.env.saasName

      console.log('🟡🟡 looking for saas name: ', saasName)
      const response = await checkSubdomain(saasName)
      if (response.status === false) {
        console.log('the saas name is not found')
        return defaultRoutes
      }

      console.log('the saas name is found')
      return getSaasProductRoutes(defaultRoutes, response)
    }
  }
  if (
    hostname.includes('localhost') ||
    parts.length === domainLength - 1 || // main domain example.com => length = 3
    parts[0] === 'www'
  ) {
    // either it's http://localhost:3000 or it's the root domain example.com or it's the alias (CNAME) www.example.com >> in all these cases, it's our normal webapp, so we need the same routing provided by nuxt but without the sub-domain routes as they are specific to SAAS product
    return excludeSubdomainRoutes(defaultRoutes)
  } else if (
    hostname === 'staging.webapp.eventful.sa' || // staging version of the normal app is also another special case since the name length is 4, in this case we also need to exclude the sub-domain routes and return the normal ones
    parts.length === domainLength + 1 // sub of sub domain stg.web.example.com => length = 4
  ) {
    return excludeSubdomainRoutes(defaultRoutes)
  } else {
    // at this stage we have a possiblity that this is a custom sub-domain, but we need to check first if it's one of the customer who has a sub-domain registered in our system or not
    const response = await checkSubdomain(parts[0])

    if (response.status === false) {
      // in this case the domain can be such as: random.eventful.sa, but random is not registered, so we need to return the 404 page only as part of the routing paths for the user
      let routes = defaultRoutes.filter((r) => r.name.includes('404'))
      routes = routes.map((route) => {
        route.path = route.path.replace('/sub-domain/404', '')
        return route
      })
      routes.path = '/'
      return routes
    }

    let checkedDomain = parts[0]
    if (checkedDomain === 'www') {
      checkedDomain = parts[1]
    }

    if (checkedDomain === 'qosoor') {
      // in this case we want to serve a custom pages for this specific domain, but if the pages are not found, we will use the SaaS product pages as a fallback
      // return getQosoorRoutes(defaultRoutes, response)
      return getSaasProductRoutes(defaultRoutes, response)
    } else {
      // finally, at this stage we are sure that the visited domain is not the normal webapp, nor the staging version, and it's a registered sub-domain, so we need to return the sub-domain routes (SAAS product routes)
      return getSaasProductRoutes(defaultRoutes, response)
    }
  }
}
function excludeSubdomainRoutes(defaultRoutes) {
  return defaultRoutes.filter((r) => !r.name.includes('sub-domain'))
}

// eslint-disable-next-line camelcase
function getSaasProductRoutes(defaultRoutes, { id, place_name, details }) {
  let routes = defaultRoutes.filter((route) =>
    route?.name?.includes('sub-domain')
  ) // only get the routes that have sub-domain (prefix) in their name ==> this is an example of how the route object looks like: {
  /*
    {
      path: '/sub-domain',
      component: [Function: _694d61ca],
      name: 'sub-domain___ar'
    },
    {
      path: '/sub-domain-old',
      component: [Function: _f80dad16],
      name: 'sub-domain-old___ar'
    },
  */

  routes = routes.map((route) => {
    // we don't want the sub-domain prefix to be visible in the URL, so we need to remove it from the path
    route.path = route?.path?.replace('/sub-domain', '')
    route.meta = {
      // set the meta tags to enhance the SEO of the page and to show the name of the place in the title of the page in the browser's tab
      id,
      // eslint-disable-next-line camelcase
      placeName: details?.arabic_name ?? place_name,
      place: details,
    }
    return route
  })

  return routes
}

function getQosoorRoutes(
  defaultRoutes,
  { id, place_name, details },
  prefix = ''
) {
  // the initial value of the routes will be the sub-domain routes, but if the custom pages for the same path is found, we will replace it with the custom one
  let subDomainRoutes = getSaasProductRoutes(defaultRoutes, {
    id,
    place_name,
    details,
  })
  let customRoutes = defaultRoutes.filter(
    (route) => route?.name?.includes('customSaas-qosoor')
    // route?.path?.includes('customSaas/qosoor')
  )

  customRoutes = customRoutes.map((route) => {
    // we don't want the sub-domain prefix to be visible in the URL, so we need to remove it from the path
    route.path = route?.path?.replace('/customSaas/qosoor', '')
    route.meta = {
      // set the meta tags to enhance the SEO of the page and to show the name of the place in the title of the page in the browser's tab
      id,
      // eslint-disable-next-line camelcase
      placeName: details?.arabic_name ?? place_name,
      place: details,
    }
    return route
  })
  let routesToReturn = [...subDomainRoutes]

  for (let customRoute of customRoutes) {
    // replace any route that has the same path with the custom route
    const index = routesToReturn.findIndex((route) => {
      return route.path === customRoute.path
    })
    if (index > -1) {
      routesToReturn[index] = customRoute
    } else {
      routesToReturn.push(customRoute)
    }
  }

  if (prefix && prefix !== '') {
    routesToReturn = addPrefixToRoutes(routesToReturn, prefix)
  }
  return routesToReturn
}

function addPrefixToRoutes(routes, prefix) {
  return routes.map((route) => {
    route.path = prefix + route.path
    return route
  })
}
