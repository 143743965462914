import { getImageFullPath } from '~/plugins/helpers'
const state = () => ({
  bookings: [],
  servicesInMyBooking: [],
  booking: null,
  tabbyEnable: false,
  myBookingsMeta: {},
})

const mutations = {
  SET_TABBY_ENABLE_VALUE(state, value) {
    state.tabbyEnable = value
  },
  SET_BOOKINGS(state, bookings) {
    state.bookings = bookings
  },
  SET_BOOKING(state, booking) {
    state.booking = booking
  },
  REVERSE_BOOKINGS(state, bookings) {
    state.bookings = bookings.reverse()
  },
  SET_SERVICES_IN_MY_BOOKING(state, services) {
    state.servicesInMyBooking = services
  },
  SET_MY_BOOKINGS_META(state, meta) {
    state.myBookingsMeta = meta
  },
}

const actions = {
  async fetchBookings({ commit, state }, data = {}) {
    try {
      const { isPlaceApp = false, placeId = null } = data

      commit('TOGGLE_LOADER', null, { root: true })

      const params = {}
      if (isPlaceApp && placeId) {
        params.place_id = placeId
      }
      if (data.page) {
        params.page = data.page
      }
      const response = await this.$axios.$get('/myBookings', { params })
      const fetchedBookings = response.bookings.data
        .map((booking) => {
          booking.image = getImageFullPath(booking.venue.main_image)
          return booking
        })
        .reverse()

      commit('SET_MY_BOOKINGS_META', response.bookings.paging_meta)
      commit('SET_BOOKINGS', fetchedBookings)
      commit('REVERSE_BOOKINGS', fetchedBookings)
      return state.bookings
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  getBooking({ commit, state }, data) {
    const booking = state.bookings.find((booking) => {
      return booking.id == data.id
    })
    commit('SET_BOOKING', booking)
    commit('booking/SET_BOOKING', booking, { root: true })

    return booking
  },
  async setServicesMyBooking({ commit, state }, { id, payload, onFinish }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$post(
        `myBookings/${id}/services`,
        payload
      )

      onFinish(response)
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async getServicesInMyBooking({ commit, state }, { id }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$get(
        `myBookings/${id}/availableServices`
      )
      commit('SET_SERVICES_IN_MY_BOOKING', response.data.selected_services)
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async tabbyPrescoring({ commit }, id) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      let response = await this.$axios.$get(`myBookings/${id}/tabbyPrescoring`)
      commit('SET_TABBY_ENABLE_VALUE', response.is_allowed)
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  goToWhatsapp({ commit }, number) {
    window.open(`https://wa.me/${number}`, '_blank')
  },
}

export default {
  state,
  mutations,
  actions,
}
