export const state = () => ({
  isOpenedMenu: false,
  isOpenedProfileMenu: false,
})

export const mutations = {
  toggleMenu(state) {
    state.isOpenedMenu = !state.isOpenedMenu
  },
  toggleProfileMenu(state) {
    state.isOpenedProfileMenu = !state.isOpenedProfileMenu
  },
}
