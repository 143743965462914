
export default {
  install(Vue, options) {
    Vue.prototype.showConfirmingAlert = function (
      message,
      title = '',
      submit,
      onComplete = null,
      type = 'success',
      allowOutsideClick = true
    ) {
      // this.$swal(message, title, type, submit)
      this.$swal
        .fire({
          title,
          text: message,
          icon: type,
          confirmButtonText: submit,
          allowOutsideClick,
          allowEscapeKey: allowOutsideClick,
        })
        .then((result) => {
          if (onComplete) {
            onComplete(result)
          }
        })
    }

    Vue.prototype.showSuccessToast = function (message) {
      this.$toasted.show(message, {
        position: 'bottom-right',
        duration: 2000,
        type: 'success',
        icon: 'account-alert',
      })
    }

    Vue.prototype.showFailToast = function (message) {
      this.$toasted.show(message, {
        position: 'bottom-right',
        duration: 3000,
        type: 'error',
        icon: 'account-alert',
      })
    }
  },
}
