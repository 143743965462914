import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  validate,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

// import en from 'vee-validate/dist/locale/en.json'
import ar from 'vee-validate/dist/locale/ar.json'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// loop over all rules
for (const rule in rules) {
  extend(rule, {
    // eslint-disable-next-line import/namespace
    ...rules[rule], // add the rule
    message: ar.messages[rule], // add its message
  });
}
// // add custom rule
// extend('requiredBookingOption', {
//   computesRequired: true,
//   validate: value => !!value,
//   message: "This is custom required message"
// });
extend('saudi_phone', {
  validate: (value) => {
    const regex = /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
    return regex.test(value)
  },
  message: 'الرجاء إدخال رقم الجوال بالصيغة الصحيحة',
})

// Register it globally
// main.js or any entry file.
Vue.use(validate)
