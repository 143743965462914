import moment from 'moment'

const state = () => ({
  eventIntro: null,
  currentEventRequest_id: null,
})

const mutations = {
  SET_EVENT_INTRO(state, eventIntro) {
    state.eventIntro = eventIntro
  },
  SET_CURRENT_EVENT_REQUEST_ID(state, currentEventRequest_id) {
    state.currentEventRequest_id = currentEventRequest_id
  },
}

const actions = {
  async getEventIntro({ commit }, data) {
    try {
      if (data.loader) {
        commit('TOGGLE_LOADER', null, { root: true })
      }
      const response = await this.$axios.$get(
        `/eventIntros/${data.eventIntro_id}`
      )

      if (typeof response === 'object' && response !== null) {
        commit('SET_EVENT_INTRO', response)

        return response
      }

      throw response
    } finally {
      if (data.loader) {
        commit('TOGGLE_LOADER', null, { root: true })
      }
    }
  },
  async submitEventDetails({ commit, state }, data) {
    try {
      const body = new FormData()
      commit('TOGGLE_LOADER', null, { root: true })

      body.append('event_name', data.event_name)
      body.append('province_id', data.province_id)

      if (
        moment(data.expected_start_date, 'YYYY-MM-DD', true).isValid() === true
      ) {
        body.append('expected_start_date', data.expected_start_date)
      }

      if (
        moment(data.expected_end_date, 'YYYY-MM-DD', true).isValid() === true
      ) {
        body.append('expected_end_date', data.expected_end_date)
      }

      if (moment(data.event_date, 'YYYY-MM-DD', true).isValid() === true) {
        body.append('event_date', data.event_date)
      }

      if (data.is_range_date) {
        body.append('is_range_date', data.is_range_date)
      } else {
        body.append('is_range_date', false)
      }

      body.append('expected_budget', data.expected_budget)
      body.append('expected_guests_count', data.expected_guests_count)
      body.append('event_description', data.event_description)
      body.append('event_location', data.event_location)
      body.append('event_location_description', data.event_location_description)
      body.append('services_details', data.services_details)

      if (state.currentEventRequest_id !== null) {
        body.append('currentEventRequest_id', state.currentEventRequest_id)
      }

      if (data.new_attachments) {
        data.new_attachments.forEach((attachment) => {
          body.append('attachments[]', attachment)
        })
      }

      const response = await this.$axios.$post(
        `/eventIntros/${state.eventIntro.id}/requestEvent`,
        body
      )
      if (typeof response === 'object' && response !== null) {
        await commit(
          'SET_CURRENT_EVENT_REQUEST_ID',
          response.business_event_request_id
        )
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async submitPersonalInformation({ commit, state }, data) {
    try {
      const body = new FormData()
      commit('TOGGLE_LOADER', null, { root: true })

      body.append('customer_name', data.customer_name)
      body.append('customer_phone_number', data.customer_phone_number)
      body.append('customer_email', data.customer_email)
      body.append('customer_company', data.customer_company)
      body.append('customer_position', data.customer_position)

      const response = await this.$axios.$post(
        `/eventIntros/${state.currentEventRequest_id}/update`,
        body
      )
      if (typeof response === 'object' && response !== null) {
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
