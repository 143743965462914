import moment from 'moment'
export default {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        async setSearchQueryFromURL() {
          console.log('🟢🟢setSearchQueryFromURL')
          const query = this.$router.currentRoute.query
          console.log('🟢🟢query now before change is: ', query)

          if (query.date) {
            const dateAfter60Days = moment().add(60, 'day')
            const date =
              moment(this.$router.currentRoute.query.date).diff(
                moment(),
                'days'
              ) > 1
                ? moment(this.$router.currentRoute.query.date)
                : dateAfter60Days
            await this.$store.dispatch('searchBox/setSearchQuery', {
              start_date: date.locale('en').format('Y-MM-DD'),
              end_date: date.locale('en').format('Y-MM-DD'),
            })
          }
          if (this.$router.currentRoute.query.men_count) {
            await this.$store.dispatch('searchBox/setSearchQuery', {
              men_count: this.$router.currentRoute.query.men_count ?? null,
            })
          }
          if (this.$router.currentRoute.query.women_count) {
            await this.$store.dispatch('searchBox/setSearchQuery', {
              women_count: this.$router.currentRoute.query.women_count ?? null,
            })
          }

          if (this.$router.currentRoute.query.event_type) {
            await this.$store.dispatch('searchBox/setSearchQuery', {
              event_type: parseInt(
                this.$router.currentRoute.query.event_type ?? null
              ),
            })
            this.filterEventInput = this.$router.currentRoute.query.event_type
          }

          await this.$store.commit('searchBox/STORE_SEARCH_QUERY', {})
        },
        generateSearchQuery(changed = null) {
          this.$nextTick(async () => {
            try {
              if (changed !== null) {
                await this.$router
                  .replace({
                    query: {
                      ...this.$router.currentRoute.query,
                      ...changed,
                    },
                  })
                  .catch((e) => console.warn(e))
              } else {
                // eslint-disable-next-line no-lonely-if
                this.$router.isReady().then(async () => {
                  await this.$router
                    .replace({
                      query: {
                        ...this.$router.currentRoute.query,
                        date: await this.$store.state.searchBox.query
                          .start_date,
                        men_count: await this.$store.state.searchBox.query
                          .men_count,
                        women_count: await this.$store.state.searchBox.query
                          .women_count,
                        event_type: await this.$store.state.searchBox.query
                          .event_type,
                      },
                    })
                    .catch((e) => console.warn(e))
                })
                // if (!this.isPlaceApp) {
                // }
              }
            } catch (e) {
              if (e.name === 'NavigationDuplicated') {
                console.warn(e)
              }
            }
            await this.getBookingOptionsWithCurrentQuery()
          })
        },
        async getBookingOptionsWithCurrentQuery() {
          this.$store.commit('TOGGLE_LOADER', null, { root: true })
          await this.$store.dispatch('searchBox/getBookingOptions', {
            ...(await this.$store.state.searchBox.query),
            source: 'getBookingOptionsWithCurrentQuery from mixin',
          })
          this.$store.commit('TOGGLE_LOADER', null, { root: true })
        },
      },
    })
  },
}
