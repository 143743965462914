const state = () => ({
  myPlanners: [],
  planner: null,
  private_share_key: null,
  categories: null,
  selectedCategories: [],
  selectedOptions: [],
  currentCategory: null,
  redirectFromStartNewPlanner: false,
  editedPlanner: null,
})

const mutations = {
  SET_PLANNER(state, planner) {
    state.planner = planner
  },
  SET_EDITED_PLANNER(state, planner) {
    state.editedPlanner = planner
  },
  SET_PLANNER_TO_EDITED_PLANNER(state) {
    if (state.editedPlanner !== null) {
      state.planner = state.editedPlanner
    }
  },
  SET_PRIVATE_SHARE_KEY(state, private_share_key) {
    state.private_share_key = private_share_key
  },
  SET_MY_PLANNERS(state, myPlanners) {
    state.myPlanners = myPlanners
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_SELECTED_CATEGORIES(state, selectedCategories) {
    state.selectedCategories = selectedCategories
  },
  SET_SELECTED_OPTIONS(state, selectedOptions) {
    state.selectedOptions = selectedOptions
  },
  SET_CURRENT_CATEGORY(state, currentCategory) {
    state.currentCategory = currentCategory
  },
  ADD_OPTION(state, data) {
    state.selectedOptions.push(data)
  },
  REMOVE_OPTION(state, data) {
    state.selectedOptions = state.selectedOptions.filter(
      (option) =>
        option.category_id !== data.category_id ||
        option.option_id !== data.option_id
    )
  },
  SET_REDIRECT_FROM_START_NEW_PLANNER(state, redirectFromStartNewPlanner) {
    state.redirectFromStartNewPlanner = redirectFromStartNewPlanner
  },
}

const actions = {
  async getPrivatePlanner({ commit, state }, { planner_id }) {
    const response = await this.$axios.$get(`/privatePlanners/${planner_id}`, {
      params: {
        private_share_key: state.private_share_key,
      },
    })

    if (typeof response === 'object' && response !== null) {
      commit('SET_PLANNER', response)
      return response
    }

    throw response
  },
  async getPublicPlanner({ commit }, { planner_id }) {
    const response = await this.$axios.$get(`/planners/${planner_id}`)

    if (typeof response === 'object' && response !== null) {
      commit('SET_PLANNER', response)
      return response
    }

    throw response
  },
  async getPrivateShareUrl({ commit }, { planner_id }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const response = await this.$axios.$get(
        `/myPlanners/${planner_id}/getPrivateShareUrl`
      )

      if (typeof response === 'object' && response !== null) {
        commit('SET_PRIVATE_SHARE_KEY', response.private_share_key)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async toggleLikeOption({ state }, data) {
    try {
      data.private_share_key = state.private_share_key
      const response = await this.$axios.$post(
        `/planners/${state.planner.id}/toggleLikeOption`,
        data
      )
      if (typeof response === 'object' && response !== null) {
        return response
      }

      throw response
    } catch (e) {
      console.log(e)
    }
  },
  async getMyPlanners({ commit }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true }) // TODO OSAMAH: REMOVE LOADER AFTER ADDING SKELTON LOADER

      const response = await this.$axios.$get('/myPlanners')

      if (typeof response === 'object' && response !== null) {
        commit('SET_MY_PLANNERS', response.data)
        return response.data
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async getCategories({ commit, rootState }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true }) // TODO OSAMAH: REMOVE LOADER AFTER ADDING SKELTON LOADER

      const response = await this.$axios.$get(
        `/categories?event_type=${rootState.searchBox.query.event_type}`
      )

      if (typeof response === 'object' && response !== null) {
        commit('SET_CATEGORIES', response.categories)
        return response.categories
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async startNewPlanner({ commit, state, rootState }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true }) // TODO OSAMAH: REMOVE LOADER AFTER ADDING SKELTON LOADER

      const response = await this.$axios.$post(
        `/myPlanners/startNewPlanner?category_id=${state.selectedCategories[0].id}&province=${rootState.searchBox.query.province}&with_matching_options=true`
      )

      if (typeof response === 'object' && response !== null) {
        commit('SET_PLANNER', response)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true }) // TODO OSAMAH: REMOVE LOADER AFTER ADDING SKELTON LOADER
    }
  },
  async getPlannerVenues({ commit, state, rootState }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true }) // TODO OSAMAH: REMOVE LOADER AFTER ADDING SKELTON LOADER

      const categoriesVenues = []

      let guestsString = ''
      const query = rootState.searchBox.query

      query.event_type_object.guests_inputs.forEach((input) => {
        guestsString += `&${input.key}=${query[input.key]}`
      })

      await state.selectedCategories.forEach(async (category) => {
        const response = await this.$axios.$get(
          `/myPlanners/${state.planner.id}/venues?category_id=${category.id}&province=${rootState.searchBox.query.province}&with_matching_options=true&event_type=${rootState.searchBox.query.event_type}&page=1${guestsString}`
        )

        if (typeof response === 'object' && response !== null) {
          category.venues = response.data
          categoriesVenues.push(category)
        } else {
          throw response
        }
      })

      commit('SET_CURRENT_CATEGORY', state.selectedCategories[0].id)

      return categoriesVenues
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async attachOptionToPlanner({ commit, state }, dataa) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const data = {
        category_id: state.currentCategory,
        option_id: dataa.optionId,
      }

      const response = await this.$axios.$post(
        `/myPlanners/${state.planner.id}/attachOption`,
        data
      )

      if (typeof response === 'object' && response !== null) {
        if (!dataa.isEdit) {
          commit('ADD_OPTION', data)
          commit('SET_PLANNER', response)
        } else {
          commit('SET_EDITED_PLANNER', response)
        }
      } else {
        throw response
      }
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async detachOptionFromPlanner({ commit, state }, dataa) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const data = {
        category_id: state.currentCategory,
        option_id: dataa.optionId,
      }

      const response = await this.$axios.$post(
        `/myPlanners/${state.planner.id}/detachOption`,
        data
      )

      if (typeof response === 'object' && response !== null) {
        if (!dataa.isEdit) {
          commit('REMOVE_OPTION', data)
          commit('SET_PLANNER', response)
        } else {
          commit('SET_EDITED_PLANNER', response)
        }
      } else {
        throw response
      }
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async updatePlanner({ state, commit }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$post(
        `/myPlanners/${state.planner.id}`,
        data
      )

      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        commit('SET_PLANNER', response.planner)
        return
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
}

export default {
  state,
  mutations,
  actions,
}
