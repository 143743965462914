import Vue from 'vue'
import CodeInput from 'vue-verification-code-input'
import imagePreloader from 'vue-image-preloader'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserLock,
  faTimes,
  faHeartbeat,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons'
import vWow from 'v-wow'
import * as VueGoogleMaps from 'vue2-google-maps'
import VTooltip from 'v-tooltip'

import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'

import VueTailwind from 'vue-tailwind'
import { TSelect } from 'vue-tailwind/dist/components'
import VueLazyLoad from 'vue-lazyload'

// register the plugin on vue
import Toasted from 'vue-toasted'

import VueCardFormat from 'vue-credit-card-validation'

import LightBox from 'vue-image-lightbox'

// VUE MODAL
import VModal from 'vue-js-modal'

// Use v-calendar & v-date-picker components
import VCalendar from 'v-calendar'
import VueAnalytics from 'vue-analytics'
import { log } from '~/plugins/console'

// import * as VValidate from 'vee-validate';
// Vue.component('VForm', VValidate.Form)
// Vue.component('VField', VValidate.Field)
// Vue.component('VErrorMessage', VValidate.ErrorMessage)

// import ValidationRules from './ValidationRules'
// Vue.use(ValidationRules)

Vue.use(VueCardFormat)
Vue.use(VTooltip)

const settings = {
  't-select': {
    component: TSelect,
    props: {
      fixedClasses:
        'outline-none ring-none focus:ring-none focus:outline-none py-2 ',
      wrapped: false,
      classes: {
        wrapper: 'relative outline-none flex py-2 ',
        input: 'items-center justify-center py-2 ',
      },
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success:
          'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
}

Vue.use(vWow)

Vue.use(VueLazyLoad)

Vue.use(VueTailwind, settings)

Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.component('CodeInput', CodeInput)
Vue.use(imagePreloader)

library.add(faUserLock)
library.add(faHeartbeat)
library.add(faTimes)
library.add(faUnlockAlt)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.googleMapApiKey,
    // libraries: 'places',
  },
})

// Vue.use(VueGoogleMap, {
//   load: {
//     apiKey: process.env.googleMapApiKey,
//     libraries: [
//       /* rest of libraries */
//     ],
//   },
// })

Vue.use(Toasted, {
  iconPack: 'custom-class', // set your iconPack, defaults to material. material|fontawesome|custom-class
})

Vue.component('LightBox', LightBox)

Vue.use(VCalendar, {
  componentPrefix: 'v', // Use <vc-calendar /> instead of <v-calendar />
})
Vue.use(VModal)

// APPLE PAY Manager
// import ApplepayManager from './ApplepayManager'
// Vue.use(ApplepayManager)

window.dataLayer = window.dataLayer || []
function gtag() {
  window.dataLayer.push(arguments)
}
gtag('js', new Date())

gtag('config', process.env.GOOGLE_ANALYTICS_ID ?? 'UA-110310321-1')

Vue.prototype.log = log
