import { getImageFullPath } from '~/plugins/helpers'

import Cookies from 'js-cookie'
import axios from 'axios'
const state = () => ({
  sessionId: null,
  place: null,
  invoice: {
    booking: 0,
    extra: 0,
    discount: 0,
    vat: 0,
    total: 0,
  },
  query: process.browser
    ? JSON.parse(
        window.localStorage.getItem(
          'query_' + process.env.CACHE_APP_VERSION ?? '-dev'
        )
      )
    : null ?? null,
  bookingObject: null,

  booking: null, //from server
  external_services: [], //from server
  internal_services: [], //from server
  receipt: null, //from server
  option: {}, //from server
  payment_details: null,
  cities: [],
  isMada: false,
  selectedService: null,
  bookingNote: null,
  selectedServices: [],
})

const mutations = {
  SET_PLACE(state, place) {
    state.place = place
  },

  SET_CITIES(state, cities) {
    state.cities = cities
  },

  SET_OPTION(state, option) {
    state.option = option
  },

  SET_BOOKING_OBJECT(state, object) {
    state.bookingObject = object
  },
  SET_SEARCH_QUERY(state, object) {
    state.query = object
  },
  SET_BOOKING(state, booking) {
    state.booking = booking
  },
  SET_EXTERNAL_SERVICE(state, external_services) {
    state.external_services = external_services
  },
  SET_INTERNAL_SERVICE(state, internal_services) {
    state.internal_services = internal_services
  },
  ADD_SERVICE_TO_CART(state, { service, bookingNote }) {
    state.selectedService = service
    state.bookingNote = bookingNote
  },
  SET_RECEIPT(state, receipt) {
    state.receipt = receipt
    let details = receipt.price_details
    state.invoice = {
      booking: details.total_option_price,
      extra: details.total_extra_price,
      discount: receipt.discount,
      vat: receipt.tax,
      total: receipt.total_price_with_tax_with_services,
      // total: details.total_price_with_tax,
    }
  },
  SET_PAYMENT_DETAILS(state, details) {
    state.payment_details = details
  },
  SET_IS_MADA(state, isMada) {
    state.isMada = isMada
  },
  SET_SELECTED_SERVICES(state, services) {
    state.selectedServices = services
  },
}
const actions = {
  async startBooking({ commit, state, rootState }, data) {
    const query = rootState.searchBox.query
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const body = {
        start_date: query.start_date,
        end_date: query.end_date,
        option_id: state.bookingObject.option,
        men_count: query.people_count ?? query.men_count,
        women_count: query.women_count,
        event_id: query.event_type,
        is_separated: false,
      }

      if(rootState.searchBox.selected_start_time) {
        body.start_time = rootState.searchBox.selected_start_time
        body.end_time = rootState.searchBox.selected_end_time
      }

      const response = await this.$axios.$post(
        `/venues/${data.id}/startBooking`,
        body
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        commit('SET_PLACE', response.venue)
        commit('SET_BOOKING', response.booking)
        commit(
          'SET_EXTERNAL_SERVICE',
          response.external_services.map((e) => {
            // e.main_image = getImageFullPath(e.main_image);
            return e
          })
        )
        commit(
          'SET_INTERNAL_SERVICE',
          response.internal_services.map((e) => {
            // e.main_image = getImageFullPath(e.main_image);
            return e
          })
        )
        commit('SET_OPTION', response.option)
        commit('SET_RECEIPT', response.receipt)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async getCities({ commit }) {
    try {
      const response = await this.$axios.$get(`/cities`)
      if (
        response !== null &&
        typeof response === 'object' &&
        response.length > 0
      ) {
        let cities = response.map((city) => {
          city.id = city.id
          city.label = city.name
          city.value = city.name
          return city
        })
        commit('SET_CITIES', cities)
        return response
      }

      throw response
    } finally {
    }
  },

  addBookingDetails({ commit, state }, data) {
    let myData = { ...state.bookingObject, ...data } ?? data
    commit('SET_BOOKING_OBJECT', myData)
  },

  async completeBooking({ commit, state }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$post(
        `/myBookings/${state.booking.id}/completeBooking`,
        data
      )

      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        commit('SET_PLACE', response.venue)
        commit('SET_BOOKING', response.booking)
        commit('SET_RECEIPT', response.receipt)
        commit('SET_PAYMENT_DETAILS', response.payment_details)

        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
  async initBooking({ commit, state }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const response = await this.$axios.$post(
        `/myBookings/${state.booking.id}/initBooking`,
        data
      )

      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        commit('SET_PLACE', response.venue)
        commit('SET_BOOKING', response.booking)
        commit('SET_RECEIPT', response.receipt)
        commit('SET_PAYMENT_DETAILS', response.payment_details)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async applyCoupon({ commit, state }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$post(
        `myBookings/${state.booking.id}/applyCoupon`,
        data
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('data')
      ) {
        commit('SET_RECEIPT', response.data.receipt)
        commit('SET_PAYMENT_DETAILS', response.data.receipt.payment_details)

        return response.message
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async cancelBooking({ commit, state }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      const response = await this.$axios.$post(
        `myBookings/${data.id}/cancelNonConfirmedBooking`,
        data.payload
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        this.$swal({
          text: response.success_message ? 'تم حذف الحجز' : 'حدث خطأ',
          icon: response.success_message ? 'success' : 'error',
          dangerMode: true,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 2000,
        })
        this.$router.push('/dashboard/bookings')
        return response.success_message
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async payDeposit({ commit, state, rootState }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      commit(
        'SET_IS_MADA',
        checkIsMadaCard(data.number, rootState.notProductionEnv)
      )

      const paymentData = {
        token: data.token,
        sandbox: process.env.paymentInSandbox,
      }

      if (state.isMada) {
        paymentData.is_mada_card = state.isMada
      }
      const response = await this.$axios.$post(
        `/myBookings/${state.booking.id}/payDeposit`,
        paymentData
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        (response.hasOwnProperty('success_message') ||
          response.hasOwnProperty('payment_confirmation_url'))
      ) {
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async payRemaining({ commit, state, rootState }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })
      commit(
        'SET_IS_MADA',
        checkIsMadaCard(data.number, rootState.notProductionEnv)
      )

      const paymentData = {
        token: data.token,
        sandbox: process.env.paymentInSandbox,
      }

      if (state.isMada) {
        paymentData.is_mada_card = state.isMada
      }
      const response = await this.$axios.$post(
        `/myBookings/${state.booking.id}/payRemaining`,
        paymentData
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        (response.hasOwnProperty('success_message') ||
          response.hasOwnProperty('payment_confirmation_url'))
      ) {
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async payDepositLater({ commit, state }) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$post(
        `/myBookings/${state.booking.id}/payDepositLater`,
        {
          // "customer_notes" : null
        }
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('booking')
      ) {
        // commit('SET_PLACE', response.venue)
        commit('SET_BOOKING', response.booking)
        // commit('SET_RECEIPT', response.receipt)
        // commit('SET_PAYMENT_DETAILS', response.payment_details)

        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async getBooking({ commit, state }, data) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$post(
        `myBookings/${data.id}/completeBooking`
      )
      if (
        response !== null &&
        typeof response === 'object' &&
        response.hasOwnProperty('success_message')
      ) {
        commit('SET_RECEIPT', response.receipt)
        commit('SET_PAYMENT_DETAILS', response.receipt.payment_details)
        commit('SET_PLACE', response.venue)
        commit('SET_BOOKING', response.booking)
        return response.success_message
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },

  async bookingDetailes({ commit, state }, bookingId) {
    try {
      commit('TOGGLE_LOADER', null, { root: true })

      const response = await this.$axios.$get(`myBookings/${bookingId}`)
      commit('SET_BOOKING', response.booking)
    } finally {
      commit('TOGGLE_LOADER', null, { root: true })
    }
  },
}

/// helpers

function checkIsMadaCard(number, notProductionEnv) {
  let madaBins = [
    '588845',
    '440647',
    '440795',
    '446404',
    '457865',
    '968208',
    '588846',
    '493428',
    '539931',
    '558848',
    '557606',
    '968210',
    '636120',
    '417633',
    '468540',
    '468541',
    '468542',
    '468543',
    '968201',
    '446393',
    '588847',
    '400861',
    '409201',
    '458456',
    '484783',
    '968205',
    '462220',
    '455708',
    '588848',
    '455036',
    '968203',
    '486094',
    '486095',
    '486096',
    '504300',
    '440533',
    '489317',
    '489318',
    '489319',
    '445564',
    '968211',
    '401757',
    '410685',
    '432328',
    '428671',
    '428672',
    '428673',
    '968206',
    '446672',
    '543357',
    '434107',
    '431361',
    '604906',
    '521076',
    '588850',
    '968202',
    '535825',
    '529415',
    '543085',
    '524130',
    '554180',
    '549760',
    '588849',
    '968209',
    '524514',
    '529741',
    '537767',
    '535989',
    '536023',
    '513213',
    '585265',
    '588983',
    '588982',
    '589005',
    '508160',
    '531095',
    '530906',
    '532013',
    '588851',
    '605141',
    '968204',
    '422817',
    '422818',
    '422819',
    '428331',
    '483010',
    '483011',
    '483012',
    '589206',
    '968207',
    '419593',
    '439954',
    '407197',
    '407395',
  ]
  // just for testing cards
  if (notProductionEnv) {
    madaBins.push('424242')
  }
  return madaBins.some((madaBins) => number.startsWith(madaBins))
}

export default {
  state,
  mutations,
  actions,
}
