const errors = []
export function disableLogs() {
  console.log = () => {
    errors.push(arguments)
  }
  console.warn = () => {}
  console.info = () => {}
  console.clear()
}

// eslint-disable-next-line no-unused-expressions
process.env.NODE_ENV === 'production' ? disableLogs() : null

export const log = (...args) =>
  process.env.NODE_ENV !== 'production' ? console.log(args) : null

export const dd = (...args) => {
  console.log(...args)
  process.exit(1)
}

if (process.browser) {
  window.log = log
  window.dd = dd
  window.errorsLog = errors
}
