
export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        currentUser() {
          return this.$store.state.login.user
        },

        isLoggedIn() {
          return this.$store.state.isLoggedIn
        },
        isPlaceApp() {
          return this.$store.state.placeApp.isPlaceApp
        },
        isHiddenAppHint() {
          return this.$store.state.isHiddenAppHint
        },

        currentPlaceAppBaseUrl(){
          return this.localePath('/')
          //
          // const currentPlace = this.$store.state.home.place
          // if(!currentPlace)
          // {
          //   return this.localePath('/')
          // }
          // return this.localePath(`/places/preview/${currentPlace.id}`)
        }
      }
    })
  }
}
