import moment from 'moment'

const state = () => ({
  businessPackages: [],
  businessOptions: [],
  categories: [],
  businessBookingOptions: [],
  myEventRequests: [],
  currentEventRequest: null,
  isEditEventRequest: false,
})

const mutations = {
  SET_BUSINESS_PACKAGES(state, businessPackages) {
    state.businessPackages = businessPackages
  },
  SET_BUSINESS_OPTIONS(state, businessOptions) {
    state.businessOptions = businessOptions
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_BUSINESS_BOOKING_OPTIONS(state, businessBookingOptions) {
    state.businessBookingOptions = businessBookingOptions
  },
  SET_MY_EVENT_REQUESTS(state, myEventRequests) {
    state.myEventRequests = myEventRequests
  },
  SET_CURRENT_EVENT_REQUESTS(state, currentEventRequest) {
    state.currentEventRequest = currentEventRequest
  },
  SET_IS_EDIT_EVENT_REQUESTS(state, isEditEventRequest) {
    state.isEditEventRequest = isEditEventRequest
  },


}
const actions = {
  async getBusinessPackages({ commit }) {
    try {
      commit('TOGGLE_LOADER', null, { root:true });
      const response = await this.$axios.$get('/business_event_packages')
      if (typeof response === 'object' && response !== null) {

        commit('SET_BUSINESS_PACKAGES', response.business_event_packages)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root:true });
    }
  },
  async getBusinessOptions({ commit }, {business_event_package_id}) {
    try {
      commit('TOGGLE_LOADER', null, { root:true });
      const response = await this.$axios.$get(`/business_event_options/${business_event_package_id}`)
      if (typeof response === 'object' && response !== null) {

        commit('SET_BUSINESS_OPTIONS', response.business_event_options)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root:true });
    }
  },
  async getMyEventRequests({ commit }, loader = false) {
    try {
      if (!loader) {
        commit('TOGGLE_LOADER', null, { root: true })
      }
      const response = await this.$axios.$get('/myEventRequests')
      if (typeof response === 'object' && response !== null) {
        console.log(response.data);
        commit('SET_MY_EVENT_REQUESTS', response.data)
        return response
      }

      throw response
    } finally {
      if (!loader) {
        commit('TOGGLE_LOADER', null, { root: true })
      }
    }
  },
  async getCategories({ commit }, {business_option_id}) {
    try {
      commit('TOGGLE_LOADER', null, { root:true });
      const response = await this.$axios.$get(`/getCategoriesOfBusinessOption/${business_option_id}`)
      if (typeof response === 'object' && response !== null) {
        commit('SET_CATEGORIES', response)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root:true });
    }
  },
  async getBusinessBookingOptions({ commit }, {business_option_id}) {
    try {
      commit('TOGGLE_LOADER', null, { root:true });
      const response = await this.$axios.$get(`/business_event_request/booking_options/${business_option_id}`)
      if (typeof response === 'object' && response !== null) {
        commit('SET_BUSINESS_BOOKING_OPTIONS', response.bookingOptions)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root:true });
    }
  },
  async initiateBusinessEvent({ commit}, data) {

    try {
      const body = new FormData();
      commit('TOGGLE_LOADER', null, { root: true });

      if(data.id) body.append('event_request_id', data.id);

      if(data.business_package_id) body.append('business_package_id', data.business_package_id);
      if(data.business_option_id) body.append('business_option_id', data.business_option_id);
      if(data.booking_option_id) body.append('booking_option_id', data.booking_option_id);



      const response = await this.$axios.$post('/initiate_business_event', body)
      if (typeof response === 'object' && response !== null) {
        commit('SET_CURRENT_EVENT_REQUESTS', response.business_event_request)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });
    }
  },
  async submitEventDetails({ commit, state }, data) {
    try {
      const body = new FormData();
      commit('TOGGLE_LOADER', null, { root: true });

      body.append('event_name', data.event_name);
      // body.append('city_id', data.city_id);
      body.append('province_id', data.province_id);

      if (moment(data.expected_start_date, 'YYYY-MM-DD', true).isValid() === true) {
        body.append('expected_start_date', data.expected_start_date)
      }

      if (moment(data.expected_end_date, 'YYYY-MM-DD', true).isValid() === true) {
        body.append('expected_end_date', data.expected_end_date)
      }

      if (moment(data.event_date, 'YYYY-MM-DD', true).isValid() === true) {
        body.append('event_date', data.event_date)
      }

      if (data.is_range_date) {
        body.append('is_range_date', data.is_range_date)
      } else {
        body.append('is_range_date', false)
      }

      // body.append('budget', data.budget);
      // body.append('guests_count', data.guests_count);
      body.append('expected_budget', data.expected_budget);
      body.append('expected_guests_count', data.expected_guests_count);
      body.append('event_description', data.event_description);
      body.append('event_location', data.event_location);
      body.append('event_location_description', data.event_location_description);
      body.append('services_details', data.services_details);

      if(data.new_attachments) {
        data.new_attachments.forEach(attachment => {
          body.append('attachments[]', attachment);
        });
      }
      if(data.categories) {
        data.categories.forEach(category => {
          body.append('categories[]', category);
        });
      } else {
        body.append('categories', []);
      }

      const response = await this.$axios.$post(`/event_details_step/${state.currentEventRequest.id}`, body)
      if (typeof response === 'object' && response !== null) {
        commit('SET_CURRENT_EVENT_REQUESTS', response.business_event_request)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });
    }
  },
  async submitPersonalInformation({ commit, state }, data) {
    try {
      const body = new FormData();
      commit('TOGGLE_LOADER', null, { root: true });

      body.append('customer_name', data.customer_name);
      body.append('customer_phone_number', data.customer_phone_number);
      body.append('customer_email', data.customer_email);
      body.append('customer_company', data.customer_company);
      body.append('customer_position', data.customer_position);


      const response = await this.$axios.$post(`/personal_information_step/${state.currentEventRequest.id}`, body)
      if (typeof response === 'object' && response !== null) {
        commit('SET_CURRENT_EVENT_REQUESTS', response.business_event_request)
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });
    }
  },
  async submitBusinessEvent({ commit }, data) {
    try {
      const body = new FormData();
      commit('TOGGLE_LOADER', null, { root: true });
      body.append('customer_name', data.customer_name);
      body.append('customer_phone_number', data.customer_phone_number);
      body.append('customer_email', data.customer_email);
      body.append('customer_company', data.customer_company);
      body.append('customer_position', data.customer_position);
      body.append('event_name', data.event_name);
      body.append('city_id', data.city_id);
      body.append('event_date', data.event_date);
      body.append('budget', data.budget);
      body.append('guests_count', data.guests_count);
      body.append('event_description', data.event_description);
      body.append('event_location', data.event_location);
      body.append('event_location_description', data.event_location_description);
      if(data.business_package_id) body.append('business_package_id', data.business_package_id);
      if(data.business_option_id) body.append('business_option_id', data.business_option_id);

      data.attachments.forEach(attachment => {
        body.append('attachments[]', attachment);
      });
      if(data.categories) {
        data.categories.forEach(category => {
          body.append('categories[]', category);
        });
      } else {
        body.append('categories', []);
      }

      const response = await this.$axios.$post('/business_event_request', body)
      if (typeof response === 'object' && response !== null) {
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });
    }
  },

  async removeAttachmet({ commit }, data) {
    try {
      const body = new FormData();
      commit('TOGGLE_LOADER', null, { root: true });
      body.append('attachment_to_remove', data.attachment_to_remove);

      const response = await this.$axios.$post(`/business_event_request/remove_attachment/${data.business_request_id}`, body)
      if (typeof response === 'object' && response !== null) {
        return response
      }

      throw response
    } finally {
      commit('TOGGLE_LOADER', null, { root: true });
    }
  },

  async toggleLikeOption({ state }, data) {
    try {
      const response = await this.$axios.$post(
        `/planners/${state.currentEventRequest.planner.id}/toggleLikeOption`,
        data
      )
      if (typeof response === 'object' && response !== null) {
        return response
      }

      throw response
    } catch (e) {
      console.log(e)
    }
  },
  async setLikeValue({ state }, data) {
    try {
      const response = await this.$axios.$post(
        `/planners/${state.currentEventRequest.planner.id}/setLikeValue`,
        data
      )
      if (typeof response === 'object' && response !== null) {
        return response
      }

      throw response
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {

}

export default {
  state,
  mutations,
  actions,
  getters,
}
