
export default {
  install(Vue, options){
    Vue.mixin({
      computed: {
        notProductionEnv(){
          return process.env.appenv !== "production"
        }
      }
    })
  }
}
