

const state = () => ({
  isPlaceApp: false,
  place: {}
})

const mutations = {
  SET_PLACE_APP(state, payload){
    state.isPlaceApp = payload
  }
}
const  actions = {
}

export default {
  state,
  mutations,
  actions
}
