
const checkSubdomain = async (subdomain) => {
  try {
    let response = await fetch(process.env.baseUrl + '/check-subdomain', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': 'ar',
      },
      body: JSON.stringify({ subdomain }),
    })

    response = await response.json()

    if (
      typeof response === 'object' &&
      response !== null &&
      response.hasOwnProperty('success_message')
    ) {
      return response
    }

    return response
  } catch (err) {
    console.error(err)
    return null
  }
}

export default checkSubdomain
